import { FC } from "react";
import { HeroCTA, HeroCTASection, HeroCTAText, HeroContainer, HeroImage, HeroImageSection, HeroSubtitle, HeroTitle } from "./styles";
import wincastLogo from "../../assets/wincast_logo.png"
import hero from '../../assets/wincast_hero.png'

// eslint-disable-next-line @typescript-eslint/ban-types
type HeroProps = {}

export const Hero: FC<HeroProps> = () => {
  const title = "Fandom \n Redefined"
  const subtitle = "Wincast's software enables the world's largest sports organizations, sponsors, and partners, to understand, retain, and engage their fans through revolutionary interactive experiences."
  const ctaTitle = "Contact Us"

  return (
  <HeroContainer>
    <HeroCTASection>
      <HeroTitle>{title}</HeroTitle>
      <HeroSubtitle>{subtitle}</HeroSubtitle>
      <HeroCTA href="mailto:admin@wincast.io">
        <HeroCTAText>{ctaTitle}</HeroCTAText>
      </HeroCTA>
    </HeroCTASection>
    <HeroImageSection>
      <HeroImage src={hero} />
    </HeroImageSection>
  </HeroContainer>
  )
}