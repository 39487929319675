import { styled } from 'styled-components'

export const HeaderContainer = styled.div`
  color: white;
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20%;
`

export const HeaderButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const HeaderButtonText = styled.p<({hoverColor: string, isHover: boolean})>`
  margin-right: 40px;
  font-size: 1.5rem;
  margin-left: 10px;
  font-family: "Karla", sans-serif;
  color: ${({hoverColor, isHover}) => isHover ? hoverColor : "white"};
  text-decoration: none;

  &:hover {
    color: ${({hoverColor}) => hoverColor};
  }
`