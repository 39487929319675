import { styled } from 'styled-components'

export const FooterContainer = styled.div`
  background-position: center;
  color: black;
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 15%;
  font-family: "Karla", sans-serif;
  padding: 2rem;
  background: white;
  bottom: 0px;

  @media (max-width: 480px) {
    align-items: flex-start;
    flex-direction: column-reverse;
    height: 25%;
    width: 100%;
  }
`

export const FooterContentContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 30%;
  height: 70%;

  @media (max-width: 480px) {
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 20px;
  }
`

export const FooterLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;

  @media (max-width: 480px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`

export const FooterSocialsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;

  @media (max-width: 480px) {
    margin-top: 20px;
  }
`

export const FooterContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 75%;

  @media (max-width: 480px) {
    margin-right: 25px;
  }
`

export const FooterContentTitle = styled.div`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 10px;
`

export const FooterContentItem = styled.a`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: black;
  text-decoration: none;
`