import { FC, useState } from "react";
import headerButtonImage from '../../assets/header_button_image.png'
import { HeaderButtonText, HeaderButtonContainer } from "./styles/index";
import { headerMenuIcon } from "../../assets/headerMenuIcon";

type HeaderButtonProps = {
  linkTarget: string;
  title: string;
  hoverColor: string;
}


export const HeaderButton: FC<HeaderButtonProps> = ({ linkTarget, title, hoverColor }) => {
  const [isHover, setIsHover] = useState(false);

return (
  <HeaderButtonContainer onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
    {headerMenuIcon(isHover? hoverColor : "white")}
    <a target="_blank" href={linkTarget} style={{ textDecoration: "none", cursor: 'pointer' }} rel="noreferrer">
      <HeaderButtonText isHover={isHover} hoverColor={hoverColor}>{title}</HeaderButtonText>
    </a>
  </HeaderButtonContainer>
)
}