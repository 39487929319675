/* eslint-disable react/react-in-jsx-scope */

export const headerMenuIcon = (color) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon feather-grid">
    <path id="Rectangle 58" d="M5.86667 0H0.533333C0.238781 0 0 0.238781 0 0.533333V5.86667C0 6.16122 0.238781 6.4 0.533333 6.4H5.86667C6.16122 6.4 6.4 6.16122 6.4 5.86667V0.533333C6.4 0.238781 6.16122 0 5.86667 0Z" fill={color}/>
    <path id="Rectangle 59" d="M15.4666 0H10.1333C9.83876 0 9.59998 0.238781 9.59998 0.533333V5.86667C9.59998 6.16122 9.83876 6.4 10.1333 6.4H15.4666C15.7612 6.4 16 6.16122 16 5.86667V0.533333C16 0.238781 15.7612 0 15.4666 0Z" fill={color}/>
    <path id="Rectangle 60" d="M15.4666 9.59961H10.1333C9.83876 9.59961 9.59998 9.83839 9.59998 10.1329V15.4663C9.59998 15.7608 9.83876 15.9996 10.1333 15.9996H15.4666C15.7612 15.9996 16 15.7608 16 15.4663V10.1329C16 9.83839 15.7612 9.59961 15.4666 9.59961Z" fill={color}/>
    <path id="Rectangle 61" d="M5.86667 9.59961H0.533333C0.238781 9.59961 0 9.83839 0 10.1329V15.4663C0 15.7608 0.238781 15.9996 0.533333 15.9996H5.86667C6.16122 15.9996 6.4 15.7608 6.4 15.4663V10.1329C6.4 9.83839 6.16122 9.59961 5.86667 9.59961Z" fill={color}/>
    </g>
  </svg>
  )