import { FC } from "react";
import { FooterContainer, FooterContentColumn, FooterContentContainer, FooterContentItem, FooterContentTitle, FooterLogoContainer, FooterSocialsContainer } from "./styles";
import wincastFullLogo from "../../assets/full_wincast.png"
import instagramLogo from "../../assets/insta.png"
import linkedinLogo from "../../assets/linkedin.png"
import twitterLogo from "../../assets/twitter.png"

// eslint-disable-next-line @typescript-eslint/ban-types
type FooterProps = {}

export const Footer: FC<FooterProps> = () => {
  const title = "Coming Soon..."

  const links = [
    {title: 'About Us', linkTarget: 'https://linkedin.com/company/wincast/'}, 
    {title: 'Team', linkTarget: 'https://www.linkedin.com/company/wincast/people/'}, 
    {title: 'Contact Us', linkTarget: 'mailto: admin@wincast.io'}
  ]

  const socialLinks = {
    instagram: 'https://www.instagram.com/wincast_live/',
    twitter: 'https://twitter.com/wincastlive',
    linkedin: 'https://www.linkedin.com/company/wincast/'
  }

  return (
  <FooterContainer>
    <FooterLogoContainer>
      <img src={wincastFullLogo} alt="wincast full logo" />
      <FooterSocialsContainer>
        <div onClick={() => window.open(socialLinks.instagram, '_blank')?.focus()}>
          <img src={instagramLogo} alt="instagram logo"/>
        </div>
        <div onClick={() => window.open(socialLinks.twitter, '_blank')?.focus()}>
          <img src={twitterLogo} alt="twitter logo" />
        </div>
        <div onClick={() => window.open(socialLinks.linkedin, '_blank')?.focus()}>
          <img src={linkedinLogo} alt="linkedin logo" />
        </div>
      </FooterSocialsContainer>
    </FooterLogoContainer>
    <FooterContentContainer>
      <FooterContentColumn>
        <FooterContentTitle>Company</FooterContentTitle>
        <FooterContentItem target="_blank" href={links[0].linkTarget}>{links[0].title}</FooterContentItem>
        <FooterContentItem target="_blank" href={links[1].linkTarget}>{links[1].title}</FooterContentItem>
      </FooterContentColumn>
      <FooterContentColumn>
        <FooterContentTitle>Partners</FooterContentTitle>
        <FooterContentItem target="_blank" href={links[2].linkTarget}>{links[2].title}</FooterContentItem>
      </FooterContentColumn>
    </FooterContentContainer>
  </FooterContainer>
  )
}