import { styled } from 'styled-components'
import hero from '../../../assets/wincast_hero.png'

export const HeroContainer = styled.div`
  background-color: #1B1B23;
  background-position: center;
  color: white;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  font-family: "Karla", sans-serif;
  overflow: hidden;
  height: 100%;

  @media (max-width: 480px) {
    flex-direction: column-reverse;
  }
`

export const HeroCTASection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-top: 10%;
  margin-right: 5%;

  @media (max-width: 1400px) {
    margin-left: 3%;
    margin-right: 3%;
  }

  @media (max-width: 1200px) {
    margin-right: -5%;
  }

  @media (max-width: 980px) {
    margin-right: -10%;
  }

  @media (max-width: 480px) {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 20px;
  }
`
export const HeroTitle = styled.div`
  font-size: 8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 8rem;
  margin-left: -10px;
  text-transform: uppercase;

  @media (max-width: 1080px) {
    font-size: 6rem;
    line-height: 6rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
    line-height: 2rem;
  }
`
export const HeroSubtitle = styled.div`
  color: #918F9A;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  overflow: visible;
  margin: 50px 0;

  @media (max-width: 1800px) {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  @media (max-width: 480px) {
    margin: 20px 0;
    white-space: normal;
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
`
export const HeroCTA = styled.a`
  cursor: pointer;
  width: 300px;
  border-radius: 2rem;
  border: 2px solid white;
  margin-top: 10px;
  display: flex;
  height: 1rem;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  background: transparent;
  color: white;
  text-decoration: none;
  font-family: "Karla", sans-serif !important;

  @media (max-width: 480px) {
    width: 90%;
  }

  &:hover {
    border: double 2px transparent;
    border-radius: 2rem;
    background-image: linear-gradient(#302F38, #302F38), linear-gradient(45deg, #FF4D2D, #FFB144, #EACF57, #95E58E, #14EFC8, #129BFF, #5034FF);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-color: black;
  }
`

export const HeroCTAText = styled.p`
  z-index: 5;
`

export const HeroImage = styled.img`
  width: 50rem;
  flex-shrink: 0;

  @media (max-width: 480px) {
    width: 100%;
  }
`

export const HeroImageSection = styled.div`
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`