import { FC } from "react";
import { HeaderContainer, HeaderButtonText } from "./styles";
import wincastLogo from "../../assets/wincast_logo.svg"
import { HeaderButton } from "./HeaderButtons";
import { useScreenSize } from "../../helpers/useScreenSize";

// eslint-disable-next-line @typescript-eslint/ban-types
type HeaderProps = {}

export const Header: FC<HeaderProps> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { width } = useScreenSize()

  const buttonTitles = [
    // {title: 'About Us', linkTarget: 'https://linkedin.com/company/wincast/', hoverColor: '#FF4D2D'}, 
    // {title: 'Team', linkTarget: 'https://www.linkedin.com/company/wincast/people/', hoverColor: '#FFB144'}, 
    // {title: 'Contact Us', linkTarget: 'mailto: admin@wincast.io', hoverColor: '#14EFC8'}
  ]

  const buttons = buttonTitles.map(({ linkTarget, title, hoverColor }, i) => (
    <HeaderButton
      linkTarget={linkTarget} 
      title={title}
      hoverColor={hoverColor}
      key={`header-button-${i}`}
    />)
  )

  return (
    width >= 480 ? (
      <HeaderContainer>
      <img src={wincastLogo} alt="wincast logo" style={{ margin: "20px", width: "4rem" }} />
      {buttons}
    </HeaderContainer>
     ) : (
      <img src={wincastLogo} alt="wincast logo" style={{ margin: "20px", width: "3rem", position: "absolute" }}/>
     )
  )
}