import { FC, useEffect } from 'react';
import { styled } from 'styled-components'
import { Header } from './components/Header/Header';
import { Hero } from './components/Hero/Hero';
import { Footer } from './components/Footer/Footer';

const SiteContainer = styled.div`
  background: #1B1B23;
  overflow: hidden;
`

const App: FC = () => {
  const title = "Coming Soon..."

  return (
    <SiteContainer>
      <Header />
      <Hero />
      <Footer />
    </SiteContainer>
  );
};
export default App;
